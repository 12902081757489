<script setup>
import { ref, onMounted } from 'vue';
import useClients from "../../../hooks/useClients";
import { useRoute } from "vue-router/auto";
import ClientLayout from "@/layouts/ClientLayout.vue";
import { useToast } from 'vue-toastification';
import WarningDialog from '@/components/common/WarningDialog.vue';

const { client, fetchClient, addWebhooks, deleteWebhooks, toggleWebhookStatus } = useClients();
const route = useRoute();
const toast = useToast();

const WARNING_TITLE = "Delete Confirmation";
const deleteWebhookId = ref(null);

let payload = {
    message: `Are you sure you want to delete this Webhook?`,
    color: "red",
}

const showApiTokensForm = ref(false);
const showWebhooksForm = ref(false);
const dialog = ref(false);

const appName = ref('');
const webhookName = ref('');
const webhookEndPoint = ref('');
const tokens = ref([]);
const webHooks = ref([]);

onMounted(async () => {
    await fetchClient(route.params.id);
});

const toggleApiTokenForm = () => {
    showApiTokensForm.value = !showApiTokensForm.value;
};

const toggleWebhookForm = () => {
    showWebhooksForm.value = !showWebhooksForm.value;
};

const createApiTokens = () => {
    const newToken = {
        id: Date.now(),
        status: 'Active',
        appName: appName.value,
        token: 'generated-token',
        lastUsed: 'Never',
        created: new Date().toLocaleString()
    };
    tokens.value.push(newToken);
    appName.value = '';
    showApiTokensForm.value = false;
};

const createWebhook = async () => {
    const webhook = await addWebhooks(route.params.id, {
        name: webhookName.value,
        endpoint: webhookEndPoint.value
    })
    if (webhook) {
        toast.success("Webhook created successfully.");
        //needs to fetch webhooks after the get api is completed
        webhook.active = true;
        webHooks.value.push(webhook);
        webhookName.value = '';
        webhookEndPoint.value = '';
        showWebhooksForm.value = false;
    }
};

const deleteWebhook = async (id) => {
    deleteWebhookId.value = id;
    dialog.value = true;
}

const handleDelete = async (deleteItem) => {
    dialog.value = false;
    if (deleteItem) {
        const res = await deleteWebhooks(route.params.id, deleteWebhookId.value);
        if (res) {
            toast.success("Webhook deleted successfully.");
            //needs to fetch webhooks after the get api is completed
            webHooks.value = webHooks.value.filter(item => item.id != deleteWebhookId.value);
            deleteWebhookId.value = null;
        }
    }
}

const handleWebHookStatus = async (id, status) => {
    const res = await toggleWebhookStatus(route.params.id, id, { active: status });
    if (res) {      
        toast.success("Status updated successfully.");
        //needs to fetch webhooks after the get api is completed
        webHooks.value = webHooks.value.map(item => {
            if (item.id == id) {
                item.active = status;
            }
            return item;
        });
    }
}
</script>

<template>
    <ClientLayout v-if="client" :client="client">
        <h1 class="ml-4 text-center">Api Tokens And Webhooks</h1>
        <WarningDialog v-if="dialog" :callback="handleDelete" :msg="payload" :title="WARNING_TITLE"/>
        <v-container>
            <v-row>
                <v-col cols="12" md="12" lg="12">
                    <v-card>
                        <v-card-title>
                            <div class="d-flex">
                                <v-col>
                                    API Tokens
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn variant="text" color="blue" @click="toggleApiTokenForm">Create</v-btn>
                                </v-col>
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <div v-if="showApiTokensForm">
                                <v-form @submit.prevent="createApiTokens">
                                    <v-row no-gutters="">
                                        <v-col cols="12" md="8">
                                            <v-text-field v-model="appName" label="Enter Application Name..."
                                                variant="outlined" density="compact" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="8" class="mb-4">
                                            <v-btn color="secondary" type="submit" size="small" class="mr-4">Create
                                                Application</v-btn>
                                            <v-btn color="white" size="small" @click="toggleApiTokenForm">Cancel</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </div>
                            <div>
                                <v-row class="table-header">
                                    <v-col cols="1">Status</v-col>
                                    <v-col cols="2">App Name</v-col>
                                    <v-col cols="2">Token</v-col>
                                    <v-col cols="2">Last Used</v-col>
                                    <v-col cols="2">Created</v-col>
                                </v-row>
                                <v-row v-if="tokens.length === 0">
                                    <v-col cols="12" class="text-center">No Live API Tokens</v-col>
                                </v-row>
                                <v-row v-for="token in tokens" :key="token.id" align="center">
                                    <v-col cols="1">{{ token.status }}</v-col>
                                    <v-col cols="2">{{ token.appName }}</v-col>
                                    <v-col cols="2">{{ token.token }}</v-col>
                                    <v-col cols="2">{{ token.lastUsed }}</v-col>
                                    <v-col cols="2">{{ token.created }}</v-col>
                                    <v-col cols="3" class="d-flex mr-0">
                                        <v-btn variant="text" color="red">Disable</v-btn>
                                        <v-btn variant="text" color="red" class="pr-4">Delete</v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <v-container>
            <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                    <v-card>
                        <v-card-title>
                            <div class="d-flex">
                                <v-col>
                                    Api Webhooks
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn color="blue" @click="toggleWebhookForm" variant="text">Create</v-btn>
                                </v-col>
                            </div>
                        </v-card-title>
                        <v-card-text>
                            <div v-if="showWebhooksForm">
                                <v-form @submit.prevent="createWebhook">
                                    <v-row no-gutters="">
                                        <v-col cols="12" md="8">
                                            <v-text-field v-model="webhookName" label="Enter Application Name..."
                                                variant="outlined" density="compact" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="8">
                                            <v-text-field v-model="webhookEndPoint" label="Enter Endpoint"
                                                variant="outlined" density="compact" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="8" class="mb-4">
                                            <v-btn color="secondary" type="submit" size="small" class="mr-4">Create
                                                Application</v-btn>
                                            <v-btn color="white" @click="toggleWebhookForm" size="small">Cancel</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </div>
                            <div>
                                <v-row class="table-header">
                                    <v-col cols="1">Status</v-col>
                                    <v-col cols="2">App Name</v-col>
                                    <v-col cols="2">Endpoint</v-col>
                                    <v-col cols="2">Last Used</v-col>
                                    <v-col cols="2">Created</v-col>
                                </v-row>
                                <v-row v-if="webHooks.length === 0">
                                    <v-col cols="12" class="text-center">No Live Web Hooks</v-col>
                                </v-row>
                                <v-row v-for="wh in webHooks" :key="wh.id" align="center">
                                    <v-col cols="1">
                                        <div class="badge badge-pill" :class="wh.active ? 'badge-success' : 'badge-danger'">{{ wh.active ? "Active" : "Disabled" }}</div>
                                    </v-col>
                                    <v-col cols="2">{{ wh.name }}</v-col>
                                    <v-col cols="2">{{ wh.endpoint }}</v-col>
                                    <v-col cols="2">{{ wh.used_at }}</v-col>
                                    <v-col cols="2">{{ wh.created_at }}</v-col>
                                    <v-col cols="3" class="d-flex">
                                        <v-btn variant="text" :color="wh.active ? 'red' : 'green'" @click="handleWebHookStatus(wh.id, !wh.active)">{{ wh.active ? "Disable" : "Enable" }}</v-btn>
                                        <v-btn variant="text" color="red" @click="deleteWebhook(wh.id)">Delete</v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </ClientLayout>


</template>

<style scoped>
.v-card {
    border-radius: 8px;
    overflow: hidden;
}

.v-card-title {
    padding: 16px;
    font-size: 18px;
    font-weight: bold;
}

.v-card-text {
    padding: 16px;
}

.table-header {
    font-weight: bold;
    padding: 12px 0;
}

.v-row {
    padding: 12px 0;
    border-bottom: 1px solid #e0e0e0;
}

.text-center {
    text-align: center;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, 
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, 
    box-shadow 0.15s ease-in-out;
}
.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

.badge-success {
    background-color: green;
    color: white;
}

.badge-danger {
    background-color: red;
    color: white;
}
</style>